import React, { useEffect, useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Link, useParams } from "react-router-dom";

import SwiperCore, { Pagination, Navigation } from "swiper/core";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import Loader from "../../Loader";
import { Helmet } from "react-helmet-async";
import { useMediaQuery } from "@mui/material";
import BookingForm from "../../components/BookingForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchFaqHotels } from "../../data/faqHotels/faqHotelsSlice";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { useTranslation } from "react-i18next";
SwiperCore.use([Pagination, Navigation]);
const FaqHotels = () => {
  const { hotelUrl } = useParams();
  const { t } = useTranslation();
  const language = useSelector((state) => state.language.language);
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const { faqHotels, loading, error } = useSelector((state) => state.faqHotels);
  useEffect(() => {
    dispatch(fetchFaqHotels({ language, hotelUrl }));
  }, [dispatch, language, hotelUrl]);
  const [openPanels, setOpenPanels] = useState([]);
  const togglePanel = (index) => {
    if (openPanels.includes(index)) {
      // If panel is open, remove it from the array
      setOpenPanels(openPanels.filter((i) => i !== index));
    } else {
      // Otherwise add it to the array
      setOpenPanels([...openPanels, index]);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{faqHotels.pageDetails?.pageMetatagTitle || "faq"}</title>
            <link
              rel="canonical"
              href={`https://www.orienthg.com/${language}/${hotelUrl}/faq`}
            />
            <meta name="robots" content="index, follow" />
            <meta
              name="description"
              content={faqHotels.pageDetails?.pageMetatagDescription}
            />
          </Helmet>
          <section
            className="banner-header section-padding valign bg-img bg-fixed"
            data-overlay-dark={faqHotels.pageDetails?.pageBannerColorOverlayTo}
            style={{
              backgroundImage: `url(${
                isMobile
                  ? faqHotels.pageDetails?.pageBannerMobile
                  : isTablet
                  ? faqHotels.pageDetails?.pageBannerTablet
                  : faqHotels.pageDetails?.pageBannerPC
              })`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-left caption mt-90">
                  <Fade triggerOnce cascade direction="up">
                    {" "}
                    <h1>{t("FAQ")}</h1>
                  </Fade>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "17px", mb: 0.2 }}
                    fontSize="inherit"
                  />
                  {t("Home")}
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}`}
                >
                  {faqHotels.hotelName
                    ? faqHotels.hotelName
                    : hotelUrl === "stella-beach-resort-spa"
                    ? "Stella Beach Resort & Spa "
                    : hotelUrl === "stella-gardens-resort-spa"
                    ? "Stella Gardens Resort & Spa"
                    : hotelUrl == "stella-•-golf"
                    ? "Stella • Golf"
                    : "Stella • Sea Club"}
                </Link>

                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {faqHotels.pageDetails?.pageTitle}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <BookingForm />
          <section className="py-5 mt-5">
            <div className="container">
              <div className="row  mx-auto justify-content-center text-center">
                <div className="col-lg-10">
                  <Fade triggerOnce cascade direction="up">
                    <div className="text-15 uppercase mb-20">
                      {faqHotels.pageDetails?.pageTitle}
                    </div>
                    <h2 className="text-50 lg:text-40 md:text-30">
                      {faqHotels.pageDetails?.pageText}
                    </h2>
                  </Fade>
                </div>
              </div>
            </div>
          </section>
          <section className="pb-5 mb-5">
            <div className="container">
              <div className="row mx-auto justify-content-center">
                <div className="col-xl-10  col-lg-10">
                  <div className="accordion -type-1 row y-gap-10">
                    {faqHotels.faQs?.map((item, index) => (
                      <div key={index} className="col-12">
                        <div className="accordion__item">
                          <div
                            className="accordion__button d-flex align-baseline justify-between px-30 py-20 bg-light-1"
                            onClick={() => togglePanel(index)}
                          >
                            <div className="text-sec text-24 fw-500 lh-1">
                              {item.question}
                            </div>
                            <div className="accordion__icon">
                              {openPanels.includes(index) ? (
                                <FontAwesomeIcon icon={faMinus} />
                              ) : (
                                <FontAwesomeIcon icon={faPlus} />
                              )}
                            </div>
                          </div>
                          {openPanels.includes(index) && (
                            <div className="accordion__content">
                              <div className="px-30 py-30">
                                <p
                                  className="text-dark"
                                  dangerouslySetInnerHTML={{
                                    __html: `${item.answer}`,
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default FaqHotels;
