import React, { useEffect } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate, useParams } from "react-router-dom";

import Loader from "../../Loader";
import { Helmet } from "react-helmet-async";
import useMediaQuery from "@mui/material/useMediaQuery";
import BookingForm from "../../components/BookingForm";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardList,
  faUserCheck,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { fetchDiningType } from "../../data/diningtype/diningTypeSlice";
import { useTranslation } from "react-i18next";
const DiningType = () => {
  const { hotelUrl, filterBy } = useParams();
  const { t } = useTranslation();
  const language = useSelector((state) => state.language.language);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");

  const { diningType, loading, error } = useSelector(
    (state) => state.diningType
  );
  useEffect(() => {
    dispatch(fetchDiningType({ hotelUrl, filterBy, language }));
  }, [dispatch, hotelUrl, filterBy, language]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>
              {diningType.pageDetails?.pageMetatagTitle || t("Dining")}
            </title>
            <link
              rel="canonical"
              href={`https://www.orienthg.com/${language}/${hotelUrl}/${filterBy}`}
            />
            <meta name="robots" content="index, follow" />
            <meta
              name="description"
              content={diningType.pageDetails?.pageMetatagDescription}
            />
          </Helmet>
          <section
            className="banner-header section-padding valign bg-img bg-fixed"
            data-overlay-dark={diningType.pageDetails?.pageBannerColorOverlayTo}
            style={{
              backgroundImage: `url(${
                isMobile
                  ? diningType.pageDetails?.pageBannerMobile
                  : isTablet
                  ? diningType.pageDetails?.pageBannerTablet
                  : diningType.pageDetails?.pageBannerPC
              })`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-left caption mt-90">
                  <Fade triggerOnce cascade direction="up">
                    {" "}
                    <h1 style={{ textTransform: "capitalize" }}>
                      {diningType.pageDetails?.pageTitle}
                    </h1>
                  </Fade>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "17px", mb: 0.2 }}
                    fontSize="inherit"
                  />
                  {t("Home")}
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}`}
                >
                  {diningType.hotelName
                    ? diningType.hotelName
                    : hotelUrl === "stella-beach-resort-spa"
                    ? "Stella Beach Resort & Spa "
                    : hotelUrl === "stella-gardens-resort-spa"
                    ? "Stella Gardens Resort & Spa"
                    : hotelUrl == "stella-•-golf"
                    ? "Stella • Golf"
                    : "Stella • Sea Club"}
                </Link>

                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textTransform: "capitalize",
                  }}
                  color="text.primary"
                >
                  {diningType.pageDetails?.pageTitle
                    ? diningType.pageDetails?.pageTitle
                    : filterBy}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <BookingForm />
          <section className="py-5 mt-5">
            <div className="container">
              <div className="row  mx-auto justify-content-center text-center">
                <div className="col-lg-10">
                  <Fade triggerOnce cascade direction="up">
                    {" "}
                    <div className="text-15 uppercase mb-20">
                      <b>{t("Dining")}</b>
                    </div>
                    <h2
                      className="text-50 lg:text-40 md:text-30"
                      style={{ textTransform: "capitalize" }}
                    >
                      {diningType.pageDetails?.pageTitle}
                    </h2>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: diningType.pageDetails?.pageText,
                      }}
                    />
                  </Fade>
                </div>
              </div>
            </div>
          </section>
          <section className="services pb-5 mb-5">
            <div className="container">
              {diningType.restauransList?.map((dining, index) =>
                index % 2 === 0 ? (
                  <div className="row" key={dining.restaurantUrl}>
                    <div className="col-md-6 p-0 ">
                      <div className="img left">
                        <Link
                          to={`${
                            dining.isMoreDetails
                              ? `/${dining.hotelUrl}/dining/${dining.restaurantUrl}`
                              : "#."
                          }`}
                        >
                          <img
                            src={dining.restaurantPhoto}
                            alt={dining.restaurantName}
                          />
                        </Link>
                      </div>
                    </div>
                    <div
                      className="col-md-6 p-0 bg-cream valign "
                      data-animate-effect="fadeInRight"
                    >
                      <div className="content">
                        <div className="cont text-left">
                          <div className="info ">
                            <h6>
                              <b>{dining.restaurantsTypeName}</b>{" "}
                            </h6>
                          </div>
                          <h4>{dining.restaurantName}</h4>

                          <ul className="px-0 d-flex justify-content-left col-12 flex-wrap">
                            {dining.dressCode && (
                              <li className="w-auto me-2">
                                <b>
                                  {" "}
                                  <FontAwesomeIcon
                                    icon={faUserCheck}
                                    className="me-2"
                                  />
                                  {t("Dress Code")}:{" "}
                                </b>
                                {dining.dressCode}
                              </li>
                            )}
                            {dining.restaurantCuisine && (
                              <li className="w-auto me-2">
                                <b>
                                  <FontAwesomeIcon
                                    icon={faUtensils}
                                    className="me-2"
                                  />
                                  {t("Cuisine")}:{" "}
                                </b>
                                {dining.restaurantCuisine}
                              </li>
                            )}
                            {dining.restaurantOpenFor && (
                              <li className="w-auto me-2">
                                <b>
                                  <FontAwesomeIcon
                                    icon={faClock}
                                    className="me-2"
                                  />
                                  {t("Open For")}:{" "}
                                </b>
                                {dining.restaurantOpenFor}
                              </li>
                            )}
                            {dining.restaurantMenu && (
                              <li className="w-auto me-2">
                                <b>
                                  <FontAwesomeIcon
                                    icon={faClipboardList}
                                    className="me-2"
                                  />
                                  {t("Menu")}:{" "}
                                </b>
                                {dining.restaurantMenu}
                              </li>
                            )}
                          </ul>
                          <p
                            className={`${!dining.isMoreDetails && "view-all"}`}
                          >
                            {" "}
                            {dining.restaurantSummery}
                          </p>
                          <div className="row  justify-content-between align-items-end">
                            {dining.openinghours && (
                              <div className="col-12 col-lg-8">
                                {" "}
                                <h6 className="fw-bold mb-1">
                                  <b>
                                    <FontAwesomeIcon
                                      className="me-2"
                                      icon={faClock}
                                    />
                                  </b>
                                  {t("Opening Hours")}:
                                </h6>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: `${dining.openinghours}`,
                                  }}
                                />
                              </div>
                            )}
                            <div className="col-12 col-lg-4 text-end">
                              {dining.isMoreDetails && (
                                <div className="butn-dark">
                                  {" "}
                                  <Link
                                    to={`/${dining.hotelUrl}/dining/${dining.restaurantUrl}`}
                                  >
                                    <span>{t("More")}</span>
                                  </Link>{" "}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="row d-flex flex-column-reverse flex-lg-row"
                    key={dining.restaurantUrl}
                  >
                    <div
                      className="col-md-6 p-0 bg-cream valign "
                      data-animate-effect="fadeInRight"
                    >
                      <div className="content">
                        <div className="cont text-left">
                          <div className="info ">
                            <h6>
                              <b>{dining.restaurantsTypeName}</b>{" "}
                            </h6>
                          </div>
                          <h4>{dining.restaurantName}</h4>

                          <ul className="px-0 d-flex justify-content-left col-12 flex-wrap">
                            {dining.dressCode && (
                              <li className="w-auto me-2">
                                <b>
                                  {" "}
                                  <FontAwesomeIcon
                                    icon={faUserCheck}
                                    className="me-2"
                                  />
                                  {t("Dress Code")}:{" "}
                                </b>
                                {dining.dressCode}
                              </li>
                            )}
                            {dining.restaurantCuisine && (
                              <li className="w-auto me-2">
                                <b>
                                  <FontAwesomeIcon
                                    icon={faUtensils}
                                    className="me-2"
                                  />
                                  {t("Cuisine")}:{" "}
                                </b>
                                {dining.restaurantCuisine}
                              </li>
                            )}
                            {dining.restaurantOpenFor && (
                              <li className="w-auto me-2">
                                <b>
                                  <FontAwesomeIcon
                                    icon={faClock}
                                    className="me-2"
                                  />
                                  {t("Open For")}:{" "}
                                </b>
                                {dining.restaurantOpenFor}
                              </li>
                            )}
                            {dining.restaurantMenu && (
                              <li className="w-auto me-2">
                                <b>
                                  <FontAwesomeIcon
                                    icon={faClipboardList}
                                    className="me-2"
                                  />
                                  {t("Menu")}:{" "}
                                </b>
                                {dining.restaurantMenu}
                              </li>
                            )}
                          </ul>
                          <p
                            className={`${!dining.isMoreDetails && "view-all"}`}
                          >
                            {" "}
                            {dining.restaurantSummery}
                          </p>
                          <div className="row justify-content-between align-items-end">
                            {dining.openinghours && (
                              <div className="col-12 col-lg-8">
                                {" "}
                                <h6 className="fw-bold mb-1">
                                  <b>
                                    <FontAwesomeIcon
                                      className="me-2"
                                      icon={faClock}
                                    />
                                  </b>
                                  {t("Opening Hours")}:
                                </h6>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: `${dining.openinghours}`,
                                  }}
                                />
                              </div>
                            )}
                            <div className="col-12 col-lg-4 text-end">
                              {dining.isMoreDetails && (
                                <div className="butn-dark">
                                  {" "}
                                  <Link
                                    to={`/${dining.hotelUrl}/dining/${dining.restaurantUrl}`}
                                  >
                                    <span>{t("More")}</span>
                                  </Link>{" "}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                      <div className="img left">
                        <Link
                          to={`${
                            dining.isMoreDetails
                              ? `/${dining.hotelUrl}/dining/${dining.restaurantUrl}`
                              : "#."
                          }`}
                        >
                          <img
                            src={dining.restaurantPhoto}
                            alt={dining.restaurantName}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default DiningType;
