import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Loader from "./../Loader";
import { useDispatch, useSelector } from "react-redux";
import { fetchLayoutGroup } from "../data/layoutGroupSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobeAsia,
  faLocation,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { Form } from "react-bootstrap";
import { subscribeNewsletter } from "../data/newsLetterSlice";
import { Alert, Snackbar, useMediaQuery } from "@mui/material";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { setLanguage } from "../data/languageSlice";
import { useTranslation } from "react-i18next";
const LayoutGroup = () => {
  const { t } = useTranslation();

  const language = useSelector((state) => state.language.language);
  const [scrollY, setScrollY] = useState(0);
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const { hotels } = useSelector((state) => state.hotels);
  const updatedHotels = hotels.map((hotel) => ({
    ...hotel,
    hotelUrl:
      hotel.hotelUrl === "stella-•-golf" ||
      hotel.hotelUrl === "stella-•-sea-club"
        ? "#."
        : hotel.hotelUrl,
  }));

  console.log(updatedHotels);
  const { layoutGroup, loading, error } = useSelector(
    (state) => state.layoutGroup
  );
  useEffect(() => {
    dispatch(fetchLayoutGroup(language));
  }, [dispatch, language]);

  const { loadingNewsletter, errorNewsletter } = useSelector(
    (state) => state.newsLetter
  );

  const [email, setEmail] = useState("");

  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [navbarOpen, setNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  const [viewMenu, setViewMenu] = useState(false);
  const [menuId, setViewMenuId] = useState(null);
  const toggleViewMenu = (num) => {
    setViewMenu(!viewMenu);
    setViewMenuId(num);
  };

  const navbarClassShow = navbarOpen ? "show" : "";
  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(true);
    dispatch(subscribeNewsletter(email));
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    // Adding the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Removing the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Determine the navbar class and logo src based on scroll position
  const navbarClass = scrollY > 100 ? "nav-scroll" : "";
  const logoSrc =
    scrollY > 100
      ? layoutGroup.groupHeader?.groupLogoColored
      : layoutGroup.groupHeader?.groupLogo;
  /*____________________________Languages____________________________________ */
  const customLanguages = layoutGroup.groupHeader?.languages
    ?.map((lang) => lang)
    .filter(
      (abbrev) =>
        abbrev.languageAbbreviation != "dd" &&
        abbrev.languageAbbreviation != language
    );
  const selectedLanguages = layoutGroup.groupHeader?.languages
    ?.map((lang) => lang)
    .find((abbrev) => abbrev.languageAbbreviation == language);
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  const toggleLanguage = (selectedLanguage) => {
    const newLanguage = selectedLanguage.languageAbbreviation;
    dispatch(setLanguage(newLanguage));
    window.location.pathname = `/${newLanguage}${window.location.pathname.substring(
      3
    )}`;
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          {/*Header*/}

          <nav className={`navbar navbar-expand-lg ${navbarClass}`}>
            <div
              className="container-fluid"
              style={{ justifyContent: "space-between" }}
            >
              <div className="logo-wrapper col-lg-2">
                <Link className="logo" to="/">
                  <img
                    src={logoSrc}
                    className="logo-img"
                    alt="logo"
                    style={{ width: isMobile ? "160px" : "100%" }}
                  />{" "}
                </Link>
              </div>

              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbar"
                aria-controls="navbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={toggleNavbar}
              >
                <span className="navbar-toggler-icon">
                  <i className="ti-menu"></i>
                </span>
              </button>

              <div
                className={`collapse navbar-collapse col-lg-10 ${navbarClassShow}`}
                id="navbar"
              >
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link"
                      to="/"
                      role="button"
                      onClick={toggleNavbar}
                    >
                      {t("Home")}
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link
                      className="nav-link"
                      to="/about-us"
                      role="button"
                      onClick={toggleNavbar}
                    >
                      {t("About Us")}
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#."
                      role="button"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                      onClick={() => {
                        toggleViewMenu(1);
                      }}
                    >
                      {t("Hotels & Resorts")} <i className="ti-angle-down"></i>
                    </Link>
                    <ul
                      className={`dropdown-menu ${
                        viewMenu && menuId === 1 ? "show" : ""
                      }`}
                    >
                      {updatedHotels?.map((hotel) => (
                        <li key={hotel.hotelUrl}>
                          <Link
                            to={`/${hotel.hotelUrl}`}
                            onClick={() => {
                              localStorage.setItem("hotelUrl", hotel.hotelUrl);
                              toggleNavbar();
                            }}
                            className="dropdown-item"
                          >
                            <span>{hotel.hotelName} </span>
                          </Link>
                        </li>
                      ))}
                      {/* <li>
                        <Link
                          to={`#.`}
                          onClick={() => {
                            
                            toggleNavbar();
                          }}
                          className="dropdown-item"
                        >
                          <span>Stella • Sea Club {`(${t("Soon")})`} </span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`#.`}
                          onClick={() => {
                            
                            toggleNavbar();
                          }}
                          className="dropdown-item"
                        >
                          <span> Stella • Golf {`(${t("Soon")})`}</span>
                        </Link>
                      </li>*/}
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/nile-cruises"
                      onClick={toggleNavbar}
                    >
                      {t("Nile Cruises")}
                    </Link>
                  </li>
                  {layoutGroup.isEnabledOffers && (
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/offer"
                        onClick={toggleNavbar}
                      >
                        {t("Offers")}
                      </Link>
                    </li>
                  )}

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#."
                      role="button"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                      onClick={() => toggleViewMenu(2)}
                    >
                      {t("Meeting & Events")} <i className="ti-angle-down"></i>
                    </Link>
                    <ul
                      className={`dropdown-menu ${
                        viewMenu && menuId === 2 ? "show" : ""
                      }`}
                    >
                      {updatedHotels?.map((hotel) => (
                        <li key={hotel.hotelUrl}>
                          <Link
                            to={`/${hotel.hotelUrl}/meetingandevents`}
                            onClick={() => {
                              localStorage.setItem("hotelUrl", hotel.hotelUrl);
                              toggleNavbar();
                            }}
                            className="dropdown-item"
                          >
                            <span>{hotel.hotelName} </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li className="nav-item dropdown" style={{ display: "none" }}>
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#."
                      role="button"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                      onClick={toggleNavbar}
                    >
                      {t("Facilities")} <i className="ti-angle-down"></i>
                    </Link>
                    <ul className="dropdown-menu">
                      {updatedHotels?.map((hotel) => (
                        <li key={hotel.hotelUrl}>
                          <Link
                            to={`/${hotel.hotelUrl}/facilities`}
                            onClick={() => {
                              localStorage.setItem("hotelUrl", hotel.hotelUrl);
                              toggleNavbar();
                            }}
                            className="dropdown-item"
                          >
                            <span>{hotel.hotelName} </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  {/*<li className="nav-item">
                    <Link className="nav-link" to="offer">
                      Offers
                    </Link>
                  </li> */}

                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/contact-us"
                      onClick={toggleNavbar}
                    >
                      {t("Contact Us")}
                    </Link>
                  </li>
                </ul>

                <ul
                  className={`navbar-nav col-lg-3 ms-auto ${
                    language == "ar" && "mx-lg-0 px-lg-0"
                  }`}
                  style={{ alignItems: "center", justifyContent: "end" }}
                >
                  <li
                    className="nav-item dropdown me-2"
                    style={{
                      visibility:
                        selectedLanguages?.languageName == "English"
                          ? "hidden"
                          : "visible",
                    }}
                  >
                    <Link
                      className="nav-link   dropdown-toggle text-uppercase"
                      to="#."
                      role="button"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                    >
                      <FontAwesomeIcon icon={faGlobeAsia} />{" "}
                      {selectedLanguages?.languageName ?? "English"}{" "}
                      <i className="ti-angle-down"></i>
                    </Link>
                    <ul className="dropdown-menu">
                      {customLanguages?.map((lang, index) => (
                        <li key={index} onClick={() => toggleLanguage(lang)}>
                          <Link
                            to="#."
                            cla
                            className="dropdown-item d-flex align-items-baseline "
                            onClick={() => toggleNavbar()}
                          >
                            <span>
                              <img
                                alt="flag"
                                src={lang.languageFlag}
                                className="pe-2"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                }}
                              />{" "}
                            </span>
                            <span>{lang.languageName}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li>
                    <a
                      href="https://www.book-secure.com/index.php?s=group&group=egstellamakadihotels&locale=en_GB&currency=EUR&stid=1sims3ss1"
                      target="_blank"
                      className="form-group"
                      rel="noreferrer"
                    >
                      <input type="submit" value={t("Book Now")} />
                    </a>
                  </li>
                </ul>
                <div></div>
              </div>
            </div>
          </nav>
          <Outlet />

          {/*Footer*/}
          <footer className="footer">
            <div className="footer-top">
              <div className="container-fluid">
                <div className="row justify-content-lg-evenly px-lg-2">
                  <div className="col-md-3 ">
                    <div className="footer-column footer-about">
                      <img
                        alt="logo"
                        src={layoutGroup.groupFooter?.groupLogo}
                        style={{ width: "75%" }}
                        className="mb-4"
                      />
                      <p className="footer-about-text">
                        {layoutGroup.groupFooter?.groupSummery}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex  justify-content-lg-evenly px-lg-3 flex-wrap">
                    {" "}
                    <div className="col-12 col-md-6  ">
                      <div className="footer-column footer-explore clearfix">
                        <h3 className="footer-title">{t("Explore")} </h3>
                        <ul className="footer-explore-list list-unstyled">
                          {updatedHotels?.map((hotel) => (
                            <li key={hotel.hotelUrl}>
                              <Link
                                to={`/${hotel.hotelUrl}`}
                                onClick={() =>
                                  localStorage.setItem(
                                    "hotelUrl",
                                    hotel.hotelUrl
                                  )
                                }
                                className="text-light"
                              >
                                {hotel.hotelName}
                              </Link>
                            </li>
                          ))}
                          {/* <li>
                            <Link
                              to={`#.`}
                               
                              className="text-light"
                            >
                              <span>Stella • Sea Club {`(${t("Soon")})`} </span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`#.`}
                               
                              className="text-light"
                            >
                              <span> Stella • Golf {`(${t("Soon")})`}</span>
                            </Link>
                          </li> */}
                          <li className="nav-item ">
                            <Link
                              className="nav-link text-light"
                              to="/about-us"
                              role="button"
                            >
                              {t("About Us")}
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link text-light"
                              to="/nile-cruises"
                            >
                              {t("Nile Cruises")}
                            </Link>
                          </li>
                          {layoutGroup.isEnabledOffers && (
                            <li>
                              <Link to="/offer" className="text-light">
                                {" "}
                                {t("Offers")}{" "}
                              </Link>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="col-12  col-md-6  ">
                      <div className="footer-column footer-explore clearfix">
                        <h3
                          className="footer-title second"
                          style={{ visibility: "hidden" }}
                        >
                          {t("Explore")}{" "}
                        </h3>
                        <ul className="footer-explore-list list-unstyled">
                          {layoutGroup.isEnabledFaq && (
                            <li>
                              <Link to="/faq" className="text-light">
                                {t("FAQ")}
                              </Link>
                            </li>
                          )}
                          <li>
                            <Link to="/online-check-in" className="text-light">
                              {t("Online Check In")}{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/privacy-policy" className="text-light">
                              {t("Privacy Policy")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/terms-conditions" className="text-light">
                              {t("Terms & Conditions")}{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/contact-us" className="text-light">
                              {t("Contact Us")}{" "}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="footer-column footer-contact">
                      <h3 className="footer-title">{t("Contact")} </h3>

                      <div className="footer-contact-info text-light">
                        <Link to={`tel:${layoutGroup.groupFooter?.groupPhone}`}>
                          <FontAwesomeIcon className="me-2" icon={faPhone} />
                          <span dir="ltr">
                            {" "}
                            {layoutGroup.groupFooter?.groupPhone}
                          </span>
                        </Link>
                        <br />
                        {layoutGroup.groupFooter?.whatsAppNumber && (
                          <Link
                            to={`https://wa.me/${layoutGroup.groupFooter?.whatsAppNumber}`}
                            target="_blank"
                            className="mt-lg-2"
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              icon={faWhatsapp}
                            />
                            <span dir="ltr">
                              {layoutGroup.groupFooter?.whatsAppNumber}
                            </span>
                          </Link>
                        )}
                        <Link
                          to={layoutGroup.groupFooter?.groupGetDeirectionLink}
                          target="_blank"
                          className="mt-lg-2"
                        >
                          <FontAwesomeIcon
                            className="me-2"
                            icon={faLocationDot}
                          />{" "}
                          {layoutGroup.groupFooter?.groupAddress}
                        </Link>
                        <Link
                          to={`mailto:${layoutGroup.groupFooter?.groupMail}`}
                          className="footer-contact-mail mt-0"
                        >
                          <FontAwesomeIcon className="me-2" icon={faEnvelope} />
                          {layoutGroup.groupFooter?.groupMail}
                        </Link>
                      </div>
                      <div className="footer-about-social-list">
                        {layoutGroup.groupFooter.groupSocials?.map(
                          (item, index) => (
                            <Link
                              target="_blank"
                              to={item.socialUrl}
                              title={item.socialName}
                              key={index}
                            >
                              <i className={`${item.socialClass}`}></i>
                            </Link>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="footer-column footer-contact">
                      <h3 className="footer-title">{t("Newsletter")}</h3>

                      <p className="text-light">
                        {t("Subscribe our Newsletter")}{" "}
                      </p>
                      <Form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <input
                            type="email"
                            placeholder={t("Enter You'r Email")}
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            name="Email"
                            className="ps-2"
                          />
                          <input type="submit" value={t("Subscribe Now")} />
                        </div>
                      </Form>
                      {!loadingNewsletter ? (
                        <Snackbar
                          open={open}
                          autoHideDuration={6000}
                          onClose={handleClose}
                        >
                          <Alert
                            onClose={handleClose}
                            severity={`${
                              errorNewsletter ? "error" : "success"
                            }`}
                            variant="filled"
                            sx={{ width: "100%" }}
                          >
                            {errorNewsletter
                              ? errorNewsletter
                              : "Thank you for subscribing!"}
                          </Alert>
                        </Snackbar>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer-bottom">
              <div className="container-fluid">
                <div className="row px-lg-5">
                  <div className="col-md-12">
                    <div className="footer-bottom-inner d-flex flex-column flex-lg-row justify-content-between">
                      <p
                        className="footer-bottom-copy-right mb-2 mb-lg-0"
                        dangerouslySetInnerHTML={{
                          __html: `${layoutGroup.groupFooter?.copyrights}`,
                        }}
                      />

                      <p className="footer-bottom-copy-right">
                        {t("Powered By")}{" "}
                        <Link to="https://titegypt.com/" target="_blank">
                          T.I.T Solutions
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
    </>
  );
};

export default LayoutGroup;
